<template>
<div
    :key="`recipients_key_${key}`"
    style="position: relative;"
>
    <div class="kt-input-icon">
        <input
            v-model="searchTerm"
            type="text"
            style="background: transparent !important;"
            :style="!showStyling ? 'border: none !important; outling: none !important' : ''"
            class="form-control"
            :class="showStyling ? 'pl-5' : 'ml-0 pl-1'"
            :placeholder="placeholder === null ? 'Course, Staff, Guardian, or Student' : placeholder"
            @blur="blurEventHandler($event)"
            @focus="focusEventHandler($event)"
            @keyup.enter="selectPortfolio(0)"
            @keydown="showPicker = true"
        >
        <span v-if="showStyling" class="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
                <i class="la la-search" />
            </span>
        </span>
    </div>

    <div
        v-if="recipients.length > 0 && showPicker"
        class="kt-widget4 p-3 mt-2 w-100"
    >
        <div
            v-for="(user, idx) in filteredRecipients"
            :key="`${idx}_portfolio`"
            class="kt-widget4__item"
            style="cursor: pointer;"
            @click.stop.prevent="selectPortfolio(idx)"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <UserAvatar :avatar-user="user">
                    <div class="kt-badge kt-badge--lg kt-badge--success">
                        {{ user.name.substring(0, 1) }}
                    </div>
                </UserAvatar>
            </div>

            <div class="kt-widget4__info">
                <a
                    href="#"
                    class="kt-widget4__username"
                    @click.stop.prevent="selectPortfolio(idx)"
                >
                    {{ user.name }}
                </a>
                <p class="kt-widget4__text">
                    {{ user.subName }}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import UserAvatar from './UserAvatar.vue';
import teacherMixins from '../store/mixins/teacherMixins';
import userMixins from '../store/mixins/userMixins';
import courseMixins from '../store/mixins/courseMixins';
import studentMixins from '../store/mixins/studentMixins';
import studentGuardianMixins from '../store/mixins/studentGuardianMixins';

export default Vue.extend({
    name: 'SearchRecipients',
    components: {
        UserAvatar,
    },
    mixins: [teacherMixins, userMixins, courseMixins, studentMixins, studentGuardianMixins],
    props: {
        onSelected: {
            type: Function,
            required: false,
            default: null,
        },
        schoolStaffId: {
            type: [String, Number],
            required: false,
            default: null,
        },
        studentEnrollmentId: {
            type: [String, Number],
            required: false,
            default: null,
        },
        allowSchoolWide: {
            type: Boolean,
            required: false,
            default: true,
        },
        allowMyStudents: {
            type: Boolean,
            required: false,
            default: true,
        },
        showCourses: {
            type: Boolean,
            required: false,
            default: true,
        },
        showHomeRooms: {
            type: Boolean,
            required: false,
            default: true,
        },
        showGradeLevels: {
            type: Boolean,
            required: false,
            default: true,
        },
        showStudentGroups: {
            type: Boolean,
            required: false,
            default: true,
        },
        showStudents: {
            type: Boolean,
            required: false,
            default: true,
        },
        showGuardians: {
            type: Boolean,
            required: false,
            default: true,
        },
        showTeachers: {
            type: Boolean,
            required: false,
            default: true,
        },
        showStyling: {
            type: Boolean,
            required: false,
            default: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            key: 0,
            searchTerm: '',
            selectedUser: null,
            showPicker: false,
            timeout: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        isAdmin() {
            const { schoolStaffId, user } = this;
            const { school } = user;
            return this.$_userMixins_isSchoolAdmin && schoolStaffId == school.schoolStaffId && !this.$_teacherMixins_getTeacherFromRoute();
        },
        schoolWide() {
            const { isAdmin, allowSchoolWide } = this;
            if (!isAdmin || !allowSchoolWide) return [];
            return [{ name: 'School-Wide', type: 'schoolWide' }];
        },
        allMyStudents() {
            const { schoolStaffId, allowMyStudents } = this;
            if (!allowMyStudents || !schoolStaffId) return [];
            return this.$_studentMixins_getStudentsForTeacher(schoolStaffId).length ? [{ name: 'All my students', type: 'allMyStudents' }] : [];
        },
        courses() {
            const { schoolStaffId, isAdmin, showCourses } = this;
            if (!schoolStaffId || !showCourses) return [];
            const courses = isAdmin ? this.$_courseMixins_getCourses() : this.$_courseMixins_getCoursesForStaffId(schoolStaffId);
            return courses.map((c) => ({ ...c, type: 'course' })) || [];
        },
        homeRooms() {
            const { students, showHomeRooms } = this;
            if (!showHomeRooms) return [];
            return students.map((s) => ({ name: `Homeroom ${s.homeRoom}`, homeRoom: s.homeRoom, type: 'homeRoom' }))
                .filter((homeRoom, idx, array) => homeRoom.homeRoom && array.map((a) => a.homeRoom).indexOf(homeRoom.homeRoom) === idx) || [];
        },
        gradeLevels() {
            const { students, showGradeLevels } = this;
            if (!showGradeLevels) return [];
            return students.map((s) => ({ name: `Grade ${s.gradeLevel}`, gradeLevel: s.gradeLevel, type: 'gradeLevel' }))
                .filter((gradeLevel, idx, array) => gradeLevel.gradeLevel && array.map((a) => a.gradeLevel).indexOf(gradeLevel.gradeLevel) === idx) || [];
        },
        studentGroups() {
            const { showStudentGroups } = this;
            if (!showStudentGroups) return [];
            return this.$store.state.database.studentGroups.filter((g) => !g.deleted).map((g) => {
                const group = { ...g };
                group.name = group.groupName;
                group.subName = 'Student Group';
                group.type = 'studentGroup';
                return group;
            }) || [];
        },
        groupings() {
            const {
                schoolStaffId, schoolWide, allMyStudents,
                courses, homeRooms, gradeLevels, studentGroups,
                isAdmin,
            } = this;
            if (!schoolStaffId) return [];
            let groupings = [];
            groupings = [...schoolWide, ...allMyStudents, ...courses, ...homeRooms, ...gradeLevels, ...studentGroups];

            return groupings.map((g, idx) => {
                const grouping = { ...g };
                grouping.isGrouping = true;
                grouping.search = `${grouping.name} ${grouping.extCourseSectionId || ''}`;
                grouping.subName = grouping.subName || grouping.extCourseSectionId || '';
                grouping.groupingId = `grouping_${idx}`;
                grouping.isAdmin = isAdmin;
                return grouping;
            });
        },
        teachers() {
            const { showTeachers } = this;
            if (!showTeachers) return [];
            return this.$_teacherMixins_getTeachers();
        },
        students() {
            const { schoolStaffId, studentEnrollmentId } = this;
            if (schoolStaffId) return this.$_studentMixins_getStudents();
            if (studentEnrollmentId) return [this.$_studentMixins_getStudentFromEnrollmentId(studentEnrollmentId)];
            return [];
        },
        guardians() {
            const { showGuardians } = this;
            if (!showGuardians) return [];
            return this.$_studentGuardianMixins_getStudentGuardians();
        },
        recipients() {
            const {
                groupings, teachers, guardians, students, showStudents,
            } = this;
            const recipients = [...groupings, ...teachers, ...guardians, ...(showStudents ? students : [])].map((r) => {
                const recipient = { ...r };
                const {
                    studentGuardianId, schoolStaffId, studentEnrollmentId, isGrouping,
                } = recipient;
                if (isGrouping) return recipient;

                if (studentGuardianId) {
                    recipient.name = `${r.guardianLastName}, ${r.guardianFirstName}`;
                    const student = students.find((s) => s.studentEnrollmentId == r.studentEnrollmentId) || null;
                    recipient.search = `${recipient.name} ${student ? student.lastName || '' : ''}, ${student ? student.firstName || '' : ''} `;
                    recipient.subName = student ? `Guardian of ${student.firstName} ${student.lastName}` : 'Guardian';
                    recipient.role = 'Guardian';
                } else if (schoolStaffId || studentEnrollmentId) {
                    recipient.name = `${r.lastName}, ${r.firstName}`;
                    recipient.search = `${recipient.name} ${r.schoolEmail || ''} ${r.googleEmail || ''}`;
                    recipient.subName = r.jobTitle || r.role;
                }
                return recipient;
            });
            return recipients;
        },
        filteredRecipients() {
            const { recipients } = this;
            const searchTerm = ('' || this.searchTerm).toLowerCase();
            return recipients.filter((r) => r.search.toLowerCase().includes(searchTerm)).slice(0, 10);
        },
    },
    watch: {
        showCourses() {
            this.key += 1;
        },
        showHomerooms() {
            this.key += 1;
        },
        showGradeLevels() {
            this.key += 1;
        },
    },
    methods: {
        blurEventHandler() {
            const v = this;
            v.timeout = setTimeout(() => {
                v.showPicker = false;
            }, 600);
        },
        focusEventHandler() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.showPicker = true;
        },
        selectPortfolio(idx) {
            this.selectedUser = this.filteredRecipients[idx];
            if (this.onSelected) {
                this.onSelected(this.selectedUser);
            }
        },
    },
});
</script>

<style scoped>
.kt-widget4 {
    position: absolute;
    width: 85%;
    z-index: 2;
    background: white;
    border: 1px solid #e2e5ec;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: clip;
}

div.kt-widget4__info {
    overflow-x: clip;
    width: 80%;
}
</style>
