var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dataSource
    ? _c(
        "div",
        {
          key: `cell_${_vm.key}_${_vm.studentEnrollmentId}_${_vm.courseSectionId}`,
          staticClass: "kt-widget5 p-0",
          staticStyle: { "max-height": "170px", "min-width": "220px" },
        },
        [
          _c("div", { staticClass: "kt-widget5__item pb-0 mb-0" }, [
            _c("div", { staticClass: "kt-widget5__content" }, [
              _c(
                "div",
                {
                  staticClass: "kt-widget5__pic pr-0",
                  staticStyle: {
                    width: "50px",
                    "max-height": "49px",
                    overflow: "hidden",
                    margin: "0",
                  },
                },
                [
                  _vm.encryptionEnabled
                    ? _c("StudentAvatar", {
                        staticClass: "kt-widget7__img",
                        staticStyle: {
                          "max-height": "38px",
                          "max-width": "38px",
                          margin: "6px",
                        },
                        attrs: { student: _vm.student },
                      })
                    : _c(
                        "UserAvatar",
                        {
                          staticClass: "kt-widget7__img",
                          staticStyle: {
                            "max-height": "38px",
                            "max-width": "38px",
                            margin: "6px",
                          },
                          attrs: { "avatar-user": _vm.student },
                        },
                        [
                          _c("div", { staticClass: "kt-media" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold",
                              },
                              [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                            ),
                          ]),
                        ]
                      ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "kt-widget5__section",
                  staticStyle: { "white-space": "nowrap", width: "165px" },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-widget5__title pb-0 w-100",
                      staticStyle: { "white-space": "nowrap" },
                      attrs: {
                        to: {
                          name: "StudentCourseOverview",
                          params: {
                            studentEnrollmentId:
                              _vm.student.studentEnrollmentId,
                            extCourseSectionId: _vm.course.extCourseSectionId,
                          },
                        },
                        event: "",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.openPanelForStudent.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.encryptionEnabled
                        ? [
                            _vm._v(
                              " " + _vm._s(_vm.student.maskedStudentName) + " "
                            ),
                          ]
                        : [
                            _vm._v(
                              " " +
                                _vm._s(_vm.student.lastName) +
                                ", " +
                                _vm._s(_vm.student.firstName) +
                                " "
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm.student.courseSection
                    ? _c("p", { staticClass: "kt-widget5__desc p-0" }, [
                        _vm.student.courseSection.courseSectionIcon
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.student.courseSection.courseSectionIcon
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.student.courseSection.courseSectionTitle
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-widget5__content" }, [
              _c("div", { staticClass: "kt-widget5__stats" }, [
                _c("span", { staticClass: "kt-widget5__number" }),
                _c("span", { staticClass: "kt-widget5__sales" }, [
                  _vm._v(_vm._s(_vm.student.homeRoom)),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _c("div", {
        staticClass: "kt-widget5 p-0",
        staticStyle: { "max-height": "170px", "min-width": "220px" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }