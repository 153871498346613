<template>
<div
    v-if="dataSource"
    :key="`cell_${key}_${studentEnrollmentId}_${courseSectionId}`"
    class="kt-widget5 p-0"
    style="max-height: 170px; min-width: 220px;"
>
    <div class="kt-widget5__item pb-0 mb-0">
        <div class="kt-widget5__content">
            <div
                class="kt-widget5__pic pr-0"
                style="width: 50px; max-height: 49px; overflow: hidden; margin: 0;"
            >
                <StudentAvatar
                    v-if="encryptionEnabled"
                    :student="student"
                    style="max-height: 38px; max-width: 38px; margin: 6px;"
                    class="kt-widget7__img"
                />
                <UserAvatar
                    v-else
                    :avatar-user="student"
                    style="max-height: 38px; max-width: 38px; margin: 6px;"
                    class="kt-widget7__img"
                >
                    <div class="kt-media">
                        <span class="kt-badge kt-badge--unified-primary kt-badge--lg kt-badge--rounded kt-badge--bold">
                            {{ initials }}
                        </span>
                    </div>
                </UserAvatar>
            </div>
            <div
                class="kt-widget5__section"
                style="white-space: nowrap; width: 165px; "
            >
                <router-link
                    style="white-space: nowrap;"
                    class="kt-widget5__title pb-0 w-100"
                    :to="{
                        name: 'StudentCourseOverview',
                        params: {
                            studentEnrollmentId: student.studentEnrollmentId,
                            extCourseSectionId: course.extCourseSectionId
                        },
                    }"
                    event=""
                    @click.native.stop.prevent="openPanelForStudent"
                >
                    <template v-if="encryptionEnabled">
                        {{ student.maskedStudentName }}
                    </template>
                    <template v-else>
                        {{ student.lastName }}, {{ student.firstName }}
                    </template>
                </router-link>
                <p
                    v-if="student.courseSection"
                    class="kt-widget5__desc p-0"
                >
                    <span v-if="student.courseSection.courseSectionIcon">
                        {{ student.courseSection.courseSectionIcon }}
                    </span>
                    {{ student.courseSection.courseSectionTitle }}
                </p>
            </div>
        </div>
        <div class="kt-widget5__content">
            <div class="kt-widget5__stats">
                <span class="kt-widget5__number" />
                <span class="kt-widget5__sales">{{ student.homeRoom }}</span>
            </div>
        </div>
    </div>
</div>
<div
    v-else
    class="kt-widget5 p-0"
    style="max-height: 170px; min-width: 220px;"
/>
</template>

<script lang="ts">
import UserAvatar from './UserAvatar.vue';
import StudentAvatar from './StudentAvatar.vue';
import panelMixins from '../store/mixins/panelMixins';

export default {
    name: 'HotCellStudentDetails',
    components: {
        UserAvatar,
        StudentAvatar,
    },
    mixins: [panelMixins],
    data() {
        return {
            // We'll need to define properties in our data object,
            // corresponding to all of the data being injected from
            // the BaseEditorComponent class, which are:
            // - hotInstance (instance of Handsontable)
            // - row (row index)
            // - col (column index)
            // - prop (column property name)
            // - TD (the HTML cell element)
            // - cellProperties (the cellProperties object for the edited cell)
            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            prop: null,
            value: null,
            cellProperties: null,
            key: 0,
        };
    },
    computed: {
        dataSource() {
            return this.value;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        isDemoSchool() {
            return this.$store.state.user.isDemoUser;
        },
        course() {
            if (!this.dataSource) return '';
            const { courseSections } = this.$store.state.database;
            const { courseSectionId } = this.dataSource;
            return courseSections.find((cs) => cs.courseSectionId == courseSectionId);
        },
        student() {
            if (!this.dataSource) return { lastName: '', firstName: '', homeRoom: '' };
            return this.dataSource.student;
        },
        courseSectionId() {
            if (!this.dataSource) return '';
            return this.dataSource.courseSectionId;
        },
        studentEnrollmentId() {
            if (!this.dataSource) return '';
            return this.dataSource.studentEnrollmentId;
        },
        initials() {
            if (!this.dataSource) return '';
            return this.student.lastName.substring(0, 2);
        },
        classBinding() {
            return [this.isDemoSchool ? 'kt-media ml-2' : ''];
        },
    },
    watch: {
        studentEnrollmentId() {
            this.key += 1;
        },
        courseSectionId() {
            this.key += 1;
        },
    },
    methods: {
        openPanelForStudent() {
            const { student, course } = this;
            this.$_panelMixins_openPanelForStudent(student, course);
        },
    },
};
</script>
<style scoped>

.kt-media-student {
  max-height: 2.3rem;
}

.kt-widget__username {
  font-size: 1.1rem !important;
  max-width: 200px !important;
}

</style>
